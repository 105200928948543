import axios from 'axios';
import { Loading,Message  } from 'element-ui';
import router from '../router/index';
import VueCookie from 'vue-cookie'

const instance = axios.create(); 
let loadingInstance = null; 

instance.interceptors.request.use(
  (config) => {
    const token = VueCookie.get('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      loadingInstance = Loading.service({
        fullscreen: true,
        text: '请求中',
      });
    }
    //loading
    
    return config;
  }
);

instance.interceptors.response.use(
  (response) => {
    if(response.data.code==401){
      Message.error(response.data.msg);
      router.push('/login'); // 使用 Vue Router 进行路由跳转
    }
    else if(response.data.code==400 || response.data.code==404)
    {
      Message.error(response.data.msg);
    }
    const token = VueCookie.get('token');
    if(token)
    {
      loadingInstance.close();
    }
    
    return response.data;
    
    //延迟loading
    return new Promise((resolve) => {
      setTimeout(() => {
        loadingInstance.close();
        resolve(response.data);
      }, 500); 
    });
  },
  (error) => {
    // 请求出错时的处理
    Message.error('请求失败，请刷新重试');
    // 关闭 loading
    loadingInstance.close();
    return Promise.reject(error);
  }
);

export default instance;
