import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import VueCookie from 'vue-cookie'
Vue.use(VueCookie)

Vue.config.productionTip = false
Vue.prototype.$imgurl = 'https://interface.hdhuabo.com';
//Vue.prototype.$imgurl = 'http://127.0.0.1/huabo-interface/public';
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
