import http from './axios-interceptor';

//导入课表
export const dr_kb = (query) => {
    return http({
        url: "/api/admin/curriculums/dr",
        method: "post",
        data: query,
    });
};

//获取校区统计
export const info = (query) => {
    return http({
        url: "/api/admin/schools/info",
        method: "get",
        params: query,
    });
};

//获取校区信息
export const all_data = (query) => {
    return http({
        url: "/api/admin/schools/all_data",
        method: "get",
        params: query,
    });
};

//获取校区数据
export const datas = (query) => {
    return http({
        url: "/api/admin/schools/datas",
        method: "get",
        params: query,
    });
};

//查看成绩
export const scores = (query) => {
    return http({
        url: "/api/admin/exams/scores",
        method: "post",
        data: query,
    });
};



