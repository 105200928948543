import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/home.vue'),
    children:[
      {
        path: '/index',
        name: 'index',
        component: () => import('../views/index.vue')
      },
      {
        path: '/student/student_edi',
        name: 'student_edi',
        component: () => import('../views/student/student_edi.vue')
      },
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login.vue')
  },
  {
    path: '/school/datas',
    name: 'datas',
    component: () => import('../views/school/datas.vue')
  },
  {
    path: '/cj',
    name: 'cj',
    component: () => import('../views/cj.vue')
  },
  {
    path: '/aiteacher',
    name: 'aiteacher',
    component: () => import('../views/aiteacher.vue')
  }
]

const router = new VueRouter({
  routes,
  mode: 'history', // 使用 history 模式
})

router.beforeEach((to, from, next) => {
  // 检查是否是刷新事件
  const isPageRefresh = to.matched.length === 0
  if (isPageRefresh && to.path !== '/index') {
    // 如果是子页面刷新，重定向到根路径
    next('/index')
  } else {
    next()
  }
})

export default router
