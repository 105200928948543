import Vue from 'vue'
import Vuex from 'vuex'
import * as school from '@/api/school.js';
Vue.use(Vuex)

export default new Vuex.Store({
  state: { 
    classrooms: [],    // 所有教室
    departments: [],    // 所有专业
    banjis: [],         // 所有系部
    dorms: [],          // 所有宿舍
    roles: [],          // 所有角色
    info: {},           // 个人信息
    weight:100,         //权重
  },
  getters: {
  },
  mutations: {
    //校区信息
    setDatas(state, data) {
      state.departments = data.departments;
      state.banjis = data.banjis;
      state.dorms = data.dorms;
      state.info = data.info;
      state.roles = data.roles;
      state.classrooms = data.classrooms;
      state.weight=data.info.weight;
    },
  },
  actions: {
    // 学校数据
    async getData({ commit }) {
      try {
        const res = await school.all_data();
        commit('setDatas', res.data);
        console.log("学校初始化数据", res.data);
      } catch (error) {
        console.error('获取专业数据失败', error);
      }
    }
  },
  modules: {
  }
})
